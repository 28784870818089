<script setup>
import AgeSelectionBox from '../molecules/AgeSelectionBox.vue'
import { sendGTagEvent } from '@/helpers/gtag'
import { sendEvent } from '@/helpers/cerebro'
import { onMounted } from 'vue'
const { title, options } = defineProps(['title', 'options'])

const emit = defineEmits(['selection'])

function sendAgeSelectionEvent(index) {
  sendGTagEvent({ event: 'age_collected', age_range: options[index] })
  emit('selection', index)
}

onMounted(() => {
  // The apps first events
  sendGTagEvent({
    event: 'flow_started'
  })

  sendEvent({
    eventName: 'onboarding_started'
  })
})
</script>

<template>
  <div class="age-container component-card">
    <p class="sub-h-xs primary-color">2 MINUTE QUIZ FOR YOUR PERSONAL PLAN</p>
    <p class="heading-5">{{ title }}</p>
    <div class="age-selection-container">
      <AgeSelectionBox
        v-for="(ageGroup, index) in options"
        :key="ageGroup"
        :age-segment="ageGroup"
        @click="sendAgeSelectionEvent(index)"
      />
      <p class="p-xs lighter legal">
        By continuing, you agree to our
        <a
          class="action-text"
          href="/policies/Terms.html"
          style="text-decoration: underline"
          target="_blank"
          >Terms & Conditions</a
        >
        and
        <a
          class="action-text"
          href="/policies/Privacy.html"
          style="text-decoration: underline"
          target="_blank"
        >
          Privacy Policy</a
        >
      </p>
    </div>
  </div>
</template>

<style scoped>
.age-container {
  padding: 16px;
  max-width: 348px;
  text-align: left;;
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 24px
}

.sub-h-xs {
  margin-top: 4px;
  padding-top: 12px
}

.age-selection-container {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.lighter {
  opacity: 0.5;
}

.heading-5 {
  font-style: normal;
}

.legal {
  text-align: center;
}


@media screen and (max-width: 850px) {
  .age-container {
    margin-top: 12px
  }
}

</style>
