<script setup>
import { computed } from 'vue'
import NextIcon from '@/assets/icons/chevron-right.svg'

const { ageSegment } = defineProps(['age-segment'])

const imageSrc = computed(() => {
  return new URL(`../../assets/images/age-${ageSegment}.png`, import.meta.url).href
})
</script>

<template>
  <div class="age-selection">
    <img :src="imageSrc" alt="Age selection" />
    <div class="label-md age-label">
        <span>Age: {{ ageSegment }}</span> <NextIcon/>
    </div>
  </div>
</template>

<style scoped>
.age-selection {
  width: calc(50% - 8px);
  max-width: 180px;
  height: 180px;
  padding: 8px;
  border-radius: 22px;
  border: 1px solid #e0e0e0;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: var(--primary-lighter);
  overflow: hidden;
}

img {
  height: 100%;
}

.label-md {
  color: #fff;
  background-color: var(--primary-base);
  padding: 4px 12px;
  position: absolute;
  display: flex;
  bottom: 0px;
  width: 100%;
}

.label-md:hover {
  background-color: var(--primary-light);
}

.age-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 850px) {
  .label-md {
    font-size: 14px;
  }
}
</style>
