import { discount } from '@/config/products'

export const steps = [
  {
    name: 'age_selection',
    component: 'AgeSelection',
    props: {
      options: ['18-26', '27-36', '37-45', '46+'],
      title: 'Stand out as the most interesting person in the room.'
    }
  },
  {
    name: 'testimonial_selection',
    component: 'TestimonialSection',
    props: {
      imgName: 'book_reading',
      title: 'Sparkle is a tool for acquiring and absorbing as much information as possible',
      text: 'We’ll help you with self-growth. First, let’s dive into your personality and tailor your personal plan.'
    }
  },
  {
    name: 'gender_selection',
    component: 'GenderSelect',
    props: {
      options:
        [
          { sendGTag: true, icon: '👨🏻', text: 'Male' },
          { sendGTag: true, icon: '👩🏻', text: ' Female' },
          { sendGTag: true, icon: '🧑🏻‍🦰', text: ' Non-Binary' },
        ],
      title: 'Select your gender identity'
    },
    dynamicResponse: false,
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'book_reading',
        title: 'Yes, it’s wonderful to have you!',
        text: 'Let’s start your journey of self-growth. We’ll begin by learning about you to craft a customized plan.'
      }
    },
    section: 'Introduction'
  },
  {
    name: 'areas_selection',
    component: 'MultiSelectCard',
    props: {
      options: [
        { iconPath: 'areas/habits.png', text: 'Habits' },
        { iconPath: 'areas/communication.png', text: 'Communication' },
        { iconPath: 'areas/personal_finance.png', text: 'Personal Finance' },
        { iconPath: 'areas/mindset.png', text: 'Mindset' },
        { iconPath: 'areas/creativity.png', text: 'Creativity' },
        { iconPath: 'areas/self_confidence.png', text: 'Self-confidence' },
        { iconPath: 'areas/nutrition.png', text: 'Nutrition' },
        { iconPath: 'areas/exercise.png', text: 'Exercise' },
        { iconPath: 'areas/love.png', text: 'Love & relationships' },
      ],
      title: 'In which areas do you want to grow?',
      text: 'Your experience won’t be limited by the choices'
    },
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'joy',
        title: 'Every journey begins with a single step',
        text: "We’d love to learn what inspires you so we can customize a growth plan just for you. Ready to continue?"
      }
    },
    section: 'Introduction'
  },
  {
    name: 'people_selection',
    component: 'MultiSelectCard',
    props: {
      options: [
        { imgPath: 'musk.png', text: 'Elon Musk' },
        { imgPath: 'winfrey.png', text: 'Oprah Winfrey' },
        { imgPath: 'james.png', text: 'LeBron James' },
        { imgPath: 'swift.png', text: 'Taylor Swift' },
        { imgPath: 'cook.png', text: 'Tim Cook' },
        { imgPath: 'robbins.png', text: 'Tony Robbins' },
        { imgPath: 'johnson.png', text: 'Dwayne Johnson' },
        { imgPath: 'ronaldo.png', text: 'Ronaldo' },
      ],
      title: 'Who inspires you with their success and personality the most?',
    },
    section: 'Introduction'
  },
  {
    name: 'personal_view',
    component: 'SelectOneList',
    props: {
      options: [
        {
          icon: '🔭',
          text: 'Generalist',
          sideText: '(Broad vision, diverse skills)',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'book_with_glasses',
              title: 'Big-picture thinking aids problem-solving.',
              text: 'It’s all about balance: there isn’t a single right perspective and details complete the bigger picture. We’ll craft your program to guide you toward combining big picture and details, helping you excel in every area.'
            }
          }
        },
        {
          icon: '🔬',
          text: 'Specialist',
          sideText: '(Detail-driven, focused expertise)',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'book_with_magnifier',
              title: 'Detail-oriented thinking aids flawless task completion.',
              text: 'Indeed, there’s no right or wrong way, and seeing the big picture is just as important. So we’ll consider this in creating your plan to help you improve your ability to see both the scope and details clearly!'
            }
          }
        }
      ],
      title: 'Which best describes your approach?'
    },
    dynamicResponse: true,
    section: 'Introduction'
  },
  {
    name: 'describe_self',
    component: 'SelectOneList',
    props: {
      title: 'How do you naturally approach situations?',
      gridCols: 2,
      options: [
        {
          icon: '🏆',
          text: 'Leader',
        },
        {
          icon: '🤝',
          text: 'Team player',
        },
        {
          icon: '⚒️',
          text: 'Problem-solver',
        },
        {
          icon: '🎭',
          text: 'Flexible',
        }
      ],
    },
    section: 'Introduction'
  },
  {
    name: 'decision_making',
    component: 'SelectOneRow',
    props: {
      title: 'It is hard for me to decide quickly.',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'victory_view',
              title: 'Great job!',
              text: "Your determination and promptness is the key to growth.",
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'embrace_view',
              title: 'It is normal to hesitate while making decisions, however it can hold you back sometimes.',
              text: 'We’re here to help you address this!'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'embrace_view',
              title: 'It is normal to hesitate while making decisions, however it can hold you back sometimes.',
              text: 'We’re here to help you address this!'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Introduction'
  },
  {
    name: 'extrovert_introvert',
    component: 'Slider',
    props: {
      options: [
        {
          emoji: '🧐',
          text: 'Introvert',
          value: 'Introvert',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'introvert',
              title: 'Cheers to the introverts!',
              text: 'You’re on a dream team with Coco Chanel, Amelia Earhart, and Marie Curie. To harness your full potential, you need a strategy as unique as you are. We’ll use what you share to create a growth plan tailored just for you.'
            }
          }
        },
        {
          emoji: '🙂',
          text: 'Balanced',
          value: 'Balanced',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'ambivert',
              title: 'Cheers to the ambivert team!',
              text: 'Ambiverts, who adapt between extroversion and introversion depending on the situation, need a special approach to find happiness.'
            }
          }
        },
        {
          emoji: '😁',
          text: 'Extrovert',
          value: 'Extrovert',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'extrovert',
              title: 'Cheers to the extroverts!',
              text: 'You’re on a dream team with Frida Kahlo, Maya Angelou, and Michelle Obama. To harness your full potential, you need a strategy as unique as you are. We’ll use what you share to create a growth plan tailored just for you.'
            }
          }
        },
      ],
      title: 'How would you describe yourself?'
    },
    dynamicResponse: true,
    section: 'Character'
  },
  {
    name: 'friendly_push',
    component: 'SelectOneRow',
    props: {
      title: 'Do you need extra motivation from time to time?',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'books_on_table',
              title: 'A supportive nudge might not work for everyone, and that’s completely fine.',
              text: 'While creating your growth plan, we will keep this in mind.'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'bell_on_books',
              title: 'Sometimes a little encouragement can make wonders.',
              text: 'While creating your growth plan, we will keep this in mind.'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'bell_on_books',
              title: 'Sometimes a little encouragement can make wonders.',
              text: 'While creating your growth plan, we will keep this in mind.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Character'
  },
  {
    name: 'time_management',
    component: 'SelectOneRow',
    props: {
      title: 'In terms of time management skills, do you feel like you need to improve?',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'FeedbackPage',
            props: {
              title: "That’s good to hear!",
              text: 'If you feel the need someday, we can alter your growth plan to adress this as well.'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'FeedbackPage',
            props: {
              title: 'Struggling to keep up with time? Many feel the same way.',
              text: 'We can help you improve your time management in a way that fits you.'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'FeedbackPage',
            props: {
              title: 'We admire your drive!',
              text: 'Effective time management is key to reaching your life goals, and we’re here to support you in mastering it.'
            }
          }
        }
      ],
    },
    dynamicResponse: true,
    section: 'Character'
  },
  {
    name: 'sex_life',
    component: 'SelectOneRow',
    props: {
      title: 'Are you pleased with your sex life and relationships?',
      options: [{ text: '❌' }, { text: '🤔' }, { text: '✅' }]
    },
    dynamicResponse: false,
    section: 'Character'
  },
  {
    name: 'body_and_desires',
    component: 'SelectOneRow',
    props: {
      title: 'Are you looking for a deeper understanding of what your body needs?',
      options: [{ text: '❌' }, { text: '🤔' }, { text: '✅' }]
    },
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'girl_looking_into_mirror',
        title: ' Self-reflection is crucial for self-growth!',
        text: 'We’ll assist you in exploring your thoughts, feelings, and actions more deeply to guide you towards becoming a better version of yourself.'
      }
    },
    dynamicResponse: false,
    section: 'Character'
  },
  {
    name: 'when_succeed_in_something',
    component: 'SelectOneList',
    props: {
      options: [
        {
          icon: '🧐',
          text: 'Focus on what could be improved',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'woman_with_coffee',
              title:
                'It’s great to look for improvement even further however do not forget to give yourself a round of applause sometimes.',
              text: 'Staying motivated on your path to success means celebrating wins and finding areas to grow—your growth plan will guide you in both.'
            }
          }
        },
        {
          icon: '🥳',
          text: 'Celebrate the success',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'man_celebrating',
              title: 'Staying positive boosts motivation significantly.',
              text: 'We will help you to continue this positive attitude and we will also identify areas to improve for you.'
            }
          }
        }
      ],
      title: 'What do you prefer after achieving success?'
    },
    dynamicResponse: true,
    section: 'Tendencies'
  },
  {
    name: 'enjoy_your_job',
    component: 'SelectOneRow',
    props: {
      title: 'Do you enjoy your job?',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'FeedbackPage',
            props: {
              title: 'You’re not alone!',
              text: 'Sparkle has helped millions find a fast way to improve their lives in minutes a day. Don’t worry, we got you!'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'FeedbackPage',
            props: {
              title: 'Thinking about making a change?',
              text: 'While transitions can be tough, having a plan makes them manageable. We’ll work with you to target your growth areas and achieve meaningful change.'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'FeedbackPage',
            props: {
              title: 'Love what you do? Fantastic!',
              text: 'Millions of Sparkle users are transforming their lives with just a few minutes a day—join them and see the difference for yourself.'
            }
          }
        }
      ],
    },
    dynamicResponse: true,
    section: 'Tendencies'
  },
  {
    name: 'work_life_balance',
    component: 'SelectOneRow',
    props: {
      title: 'Are you satisfied with your work-life balance?',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'sleeping',
              title:
                'A solid work-life balance plays a key role in staying sharp and reaching your goals.',
              text: 'Achieving balance across all life domains will be easier with your growth plan, keeping you both vibrant and content.'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: 'It’s amazing to hear that!',
              text: 'Keeping a healthy work-life balance helps you stay effective and is one of the building blocks of success.'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: 'It’s amazing to hear that!',
              text: 'Keeping a healthy work-life balance helps you stay effective and is one of the building blocks of success.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Tendencies'
  },
  {
    name: 'financial_situation',
    component: 'SelectOneRow',
    props: {
      title: 'Are you satisfied with your financial situation?',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'FeedbackPage',
            props: {
              title: 'Thank you for your openness!',
              text: 'After the quiz, you’ll gain access to insights from top bestsellers on financial management, entrepreneurship, and handling money.'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'FeedbackPage',
            props: {
              title: 'Wondering how to take the first step toward better finances?',
              text: 'A remarkable 94% of our users discovered actionable financial insights on Sparkle that significantly improved their financial expertise.'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'FeedbackPage',
            props: {
              title: 'Glad to hear that!',
              text: "Based on your responses, we'll concentrate on other key areas in your growth plan."
            }
          }
        }
      ],
    },
    dynamicResponse: true,
    section: 'Tendencies'
  },
  {
    name: 'compare_yourself',
    component: 'SelectOneRow',
    props: {
      title: 'Do you often doubt yourself or compare yourself to others?',
      options: [{ text: '❌' }, { text: '🤔' }, { text: '✅' }]
    },
    dynamicResponse: false,
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'bookshelf',
        title: '📚 1M+ Sparkle users, with 99% satisfaction, have boosted their learning through book summaries.',
        text: 'Be part of our growing community of passionate learners and find out ways to prioritize self-growth, no matter how busy you are.'
      }
    },
    section: 'Tendencies'
  },
  {
    name: 'atomic_habits',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_1',
      title: 'Do you find this book interesting?'
    },
    section: 'Tendencies'
  },
  {
    name: 'meditations',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_2',
      title: 'Do you find this book interesting?'
    },
    section: 'Tendencies'
  },
  {
    name: 'how_to_get_on_with_anyone',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_3',
      title: 'Do you find this book interesting?'
    },
    section: 'Tendencies'
  },
  {
    name: 'excellent_advice_for_living',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_4',
      title: 'Do you find this book interesting?'
    },
    section: 'Tendencies'
  },
  {
    name: 'the_miracle_morning',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_5',
      title: 'Do you find this book interesting?'
    },
    section: 'Tendencies'
  },
  {
    name: 'categories',
    component: 'MultiSelectList',
    props: {
      title: 'What categories do you like?',
      subtitle: 'Choose up to 3 categories for more precise personalization',
      options: [
        {
          name: 'Self Growth',
          id: 'TeGoKmsCMt1gAoYTAqSQ',
          iconComponentName: 'IconCat8'
        },
        {
          id: 'f3ByDrIl3OM8OzGNsCoy',
          name: 'Leadership',
          iconComponentName: 'IconCat4'
        },
        {
          name: 'Psychology',
          id: 'CuYDjrntJ8izaRRF3C8Y',
          iconComponentName: 'IconCat12'
        },

        {
          name: 'Spirituality',
          id: '8c3GkS9XLYzkJRlrWjF0',
          iconComponentName: 'IconCat13'
        },
        {
          name: 'Technology & Future',
          id: 'jUK8a5VvqF5YtMuKPWSZ',
          iconComponentName: 'IconCat7'
        },

        {
          name: 'Family',
          id: '2f5bq7kllLPimQw780Nh',
          iconComponentName: 'IconCat14'
        },

        {
          name: 'Happiness',
          id: 'LIV3AQ5seK0xa5w9gB00',
          iconComponentName: 'IconCat10'
        },

        {
          name: 'Society & Culture',
          id: 'vHwjNkCUSYN28W6fppGK',
          iconComponentName: 'IconCat15'
        },
        {
          name: 'Creativity',
          id: 'Me980EskZKrY8eHbUchf',
          iconComponentName: 'IconCat9'
        },
        {
          name: 'Communication',
          id: 'eMD6vHja2BdjD6goyFBL',
          iconComponentName: 'IconCat6'
        },
        
        {
          name: 'Business & Career',
          id: '76mWYbNXqjPEnUY30C0q',
          iconComponentName: 'IconCat1'
        },

        {
          name: 'Love & Sex',
          id: 'eco4732JGYzc59PKbCUh',
          iconComponentName: 'IconCat11'
        },

        {
          name: 'Productivity',
          id: 'HMKfoK2ll7WjLWWYd1XU',
          iconComponentName: 'IconCat5'
        },

        {
          name: 'Sports & Fitness',
          id: 'VvJDH3lgxJRfd2M57glu',
          iconComponentName: 'IconCat18'
        },

        {
          name: 'Nature & Environment',
          id: 'R79qvCmbd662BlnW6HTM',
          iconComponentName: 'IconCat16'
        },
        {
          name: 'Health',
          id: 'EmihPGxqTDUqd1STto7b',
          iconComponentName: 'IconCat17'
        },
        {
        name: 'Entrepreneurship',
          id: '50eVUFF676ctoDxG7ine',
          iconComponentName: 'IconCat2'
        },
        {
          name: 'Investment',
          id: '3NjiNbZ7fbNRCGd8IQul',
          iconComponentName: 'IconCat3'
        },
      ],
      maxSelections: 3
    },
    section: 'Tendencies'
  },
  {
    name: 'self_development_daily',
    component: 'Slider',
    props: {
      options: [
        { label: '5 min', value: 5 },
        { label: '10 min', value: 10 },
        { label: '15 min', value: 15 },
        { label: '20 min', value: 20 },
        { label: '25 min', value: 25 },
        { label: '30 min', value: 30 },
      ],
      title: 'Set your goal for timing',
      subtitle: 'Choose how much time you’ll dedicate to self-development each day.'
    }
  },
  {
    name: 'loading_pop_ups',
    component: 'LoadingPopUps',
    props: {
      title: 'Creating your personal experience...',
      popUpHeader: 'To make progress, please clarify',
      imgName: 'open_book',
      quote: {
        text: '"<span class="primary-color">Sparkle</span> is a tool for acquiring and absorbing as much information as possible”',
        author: 'Fig Nelson',
        imgName: 'fig_nelson_person'
      },
      items: [
        { icon: '🎯', title: 'Settings Goals' },
        { icon: '📈', title: 'Defining Growth Areas' },
        { icon: '📚', title: 'Searching Contents' },
        { icon: '⚔️', title: 'Adjust Challenges' }
      ],
      hasModal: true,
      options: ['No', 'Yes'],
      questions: [
        {
          title: 'Do you engage in self reflection?',
          qID: 'engage_self_reflection'
        },
        {
          title: 'Do you feel like giving up when things get tough?',
          qID: 'give_up_when_tough'
        },
        {
          title: 'Do you enjoy learning through listening?',
          qID: 'learn_by_listening'
        },
        {
          title: 'Would you say you’re likely to finish what you start?',
          qID: 'inclined_to_see'
        }
      ]
    }
  },
  {
    name: 'email_form',
    component: 'EmailForm'
  },
  {
    name: 'plan_page',
    component: 'PlanPage'
  },
]
export const QUIZ_ID = 'onboarding-1'

export const onboardings = {
  'onboarding-1': {
    steps: steps,
    discountId: discount,
    discountRate: 0.6,
    currency: 'USD'
  }
}
