<script setup>
import { computed } from 'vue'
import CrossIcon from '@/assets/icons/cross.png'
import CheckIcon from '@/assets/icons/check.png'

const { img, title, options } = defineProps(['img', 'title', 'options'])
const imageSrc = computed(() => {
  return new URL(`../../assets/images/${img}.png`, import.meta.url).href
})

const emit = defineEmits(['selection'])

const onSelect = (index) => {
  emit('selection', index)
}

</script>

<template>
  <div class="container">
    <div class="component-container component-card">
      <p class="heading-5">{{ title }}</p>
      <div class="img-wrapper">
        <div class="img-container">
          <img :src="imageSrc" alt="Image" />
        </div>
      </div>
    </div>
    <div style="flex: 1;"></div>
    <div class="like-buttons2">
      <button
        @click="onSelect(0)"
        :class="`btn option-btn ${selectedIndex == 0 ? 'selected' : ''}`">
        <img :src="CrossIcon" :alt="'Cross icon'" class="icon" />
      </button>
      <button
        @click="onSelect(1)"
        :class="`btn option-btn ${selectedIndex == 1 ? 'selected' : ''}`">
        <img :src="CheckIcon" :alt="'Check icon'" class="icon" />
      </button>
    </div>
  </div>
</template>

<style scoped>

.component-card {
  padding: 24px;
  display: flex;
  justify-content: center;
  text-align: left;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 428px;
  height: 100%;
  text-align: left;
}

.like-buttons {
  margin-top: 20px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 428px;
  width: 90%;
  gap: 8px;
  margin-left: 20px;
  margin-right: 20px
}

.like-buttons2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  gap: 8px;
  width: 90%;
}

.option-btn {
  border-radius: 100%;
  padding: 17px;
  display: flex;
  width: unset;
}

.icon {
  width: 24px;
  height: 24px;
}


.img-container {
  width: 250px;
  height: 300px;
  display: flex;
  justify-content: center
}

.img-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

img {
  height: 100%;
}

@media screen and (max-width: 850px) {
  .img-container {
    width: 200px;
    height: 250px;
  }
  .like-buttons {
    position: fixed;
    bottom: 60px;
  }

  .component-card {
    padding: 24px;
    min-height: calc(100vh - 400px);
  }
}

@media screen and (max-width: 1500px) {
  .img-container {
    width: 200px;
    height: 250px;
  }
}

</style>
