<script setup>
import { ref } from 'vue';
import Vue3Slider from 'vue-3-slider-component';
import ContinueButton from '../molecules/ContinueButton.vue'

const { title, subtitle, options } = defineProps([
  'title',
  'subtitle',
  'options',
  'selectedIndex',
])

const emit = defineEmits(['selection'])

const value = ref(options[0].value)

const onSelect = () => {
  const index = options.findIndex(opt => opt.value === value.value)
  console.log("🚀 ~ onSelect ~ index:", index)
  emit('selection', index)
}

</script>

<template>
  <div class="container">
    <div class="component-container component-card">
      <div class="heading-5">
        {{ title }}
      </div>
      <div class="mt-20">
        {{ subtitle }}
      </div>
    </div>
      <div class="slider-container">
        <Vue3Slider 
          class="slider"
          :contained="true"
          v-model="value"
          :data="options"
          :dotSize="20"
          :stepStyle="{ transform: 'scale(0.5)', backgroundColor: 'var(--neutrals-300)'}"
          :stepActiveStyle="{ transform: 'scale(0.5)', backgroundColor: 'var(--neutrals-0)'}"
          :processStyle="{ borderRadius: 0, backgroundColor: 'var(--primary-base)' }"
          :railStyle="{ borderRadius: 0, backgroundColor: 'var(--neutrals-200)' }"
          :height="20"
          tooltip="none"
          />
      <div class="labels">
        <div v-for="(option, index) in options" class="label" :style="{ width: `${(100 / options.length) - 1}%`, alignItems: index === 0 ? 'flex-start' : index === options.length - 1 ? 'flex-end' : 'center' }">
          <div v-if="option.emoji" class="emoji">{{ option.emoji }}</div>
          <div v-if="option.text" class="label-text">{{ option.text }}</div>
        </div>
      </div>
    </div>
    <ContinueButton
      :disabled="value === undefined"
      @click="onSelect"
    />
  </div>
</template>

<style scoped>

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: 428px;
}

.slider-container {
  width: 100%;
  padding-top: 40px ;
}

.slider {
  padding: 0 10px !important;
  background: linear-gradient(to right, var(--primary-base) 5%, var(--neutrals-200) 95%);
  border-radius: 10px;
}

.component-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 450px);
  max-height: 400px;
  text-align: left;
}

.selected-value {
  margin-top: 10px;
  font-weight: bold;
}

.labels {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emoji {
  font-size: 20px;
}

.label-text {
  font-size: 14px;
}

@media screen and (max-width: 850px) {
  .component-card {
    min-height: calc(100vh - 450px);
  }
  
  .slider-container {
    padding-top: 20px;
  }
}
</style>
