<script setup>
import { ref } from 'vue'
import FeedbackCard from '../atoms/FeedbackCard.vue'
import ContinueButton from '../molecules/ContinueButton.vue'
import CrossIcon from '@/assets/icons/cross.png'
import CheckIcon from '@/assets/icons/check.png'
import NeutralIcon from '@/assets/icons/neutral.png'

const { title, options, propSelectedIndex, isWithFeedback } = defineProps([
  'title',
  'options',
  'selectedIndex',
  'isWithFeedback'
])

const emit = defineEmits(['selection'])

const onSelect = (index) => {
  selectedIndex.value = index
  if (!isWithFeedback) {
    emit('selection', index)
  }
}

const selectedIndex = ref(propSelectedIndex)
</script>
<template>
  <div class="select-container">
    <p class="heading-5 component-card">{{ title }}</p>
    <div style="flex: 1;"></div>
    <FeedbackCard
      class="mt-30"
      v-if="isWithFeedback && selectedIndex !== undefined && options[selectedIndex].feedback"
      :title="options[selectedIndex].feedback.title"
      :text="options[selectedIndex].feedback.text"
    />
    <div class="options-container mt-16">
      <button
        @click="onSelect(0)"
        :class="`btn option-btn ${selectedIndex == 0 ? 'selected' : ''}`">
        <img :src="CrossIcon" :alt="'Cross icon'" class="icon" />
      </button>
      <button
        @click="onSelect(1)"
        :class="`btn option-btn ${selectedIndex == 0 ? 'selected' : ''}`">
        <img :src="NeutralIcon" :alt="'Neutral icon'" class="icon" />
      </button>
      <button
        @click="onSelect(2)"
        :class="`btn option-btn ${selectedIndex == 2 ? 'selected' : ''}`">
        <img :src="CheckIcon" :alt="'Check icon'" class="icon" />
      </button>
    </div>
    <ContinueButton
      v-if="isWithFeedback"
      :disabled="selectedIndex === undefined"
      @click="$emit('selection', selectedIndex)"
    />
  </div>
</template>
<style scoped>

.component-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 450px);
  max-height: 500px;
  text-align: left;
}

.select-container {
  display: flex;
  flex-direction: column;
}

.option-btn {
  border-radius: 100%;
  padding: 17px;
  display: flex;
  width: unset;
}

.options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px ;
  gap: 8px;
}

.icon {
  width: 24px;
  height: 24px;
}

.emoji {
  font-size: 28px;
}

@media screen and (max-width: 850px) {
  .component-card {
    min-height: calc(100vh - 380px);
  }
  .select-container {
    min-height: calc(100vh - 360px);
  }
  .options-container {
    padding-top: 0;
  }
}
</style>
