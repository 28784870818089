<script setup>
import { ref, computed } from 'vue'
import ContinueButton from '../molecules/ContinueButton.vue'

const { title, text, options } = defineProps(['title', 'text', 'options'])

const emit = defineEmits(['selection'])
const selectionStatuses = ref(options.map(() => false))

const select = (index) => {
  selectionStatuses.value[index] = !selectionStatuses.value[index]
}

const emitSelection = () => {
  const selectedOptions = selectionStatuses.value
    .map((selected, i) => (selected ? options[i] : null))
    .filter((option) => option !== null)
  emit('selection', selectedOptions)
}

const getIconSrc = (iconPath) => {
  return new URL(`../../assets/icons/${iconPath}`, import.meta.url).href
}

const getImgSrc = (imgPath) => {
  return new URL(`../../assets/images/${imgPath}`, import.meta.url).href
}

</script>

<template>
  <div class="container">
    <div class="component-card component-container">
      <p class="heading-5">{{ title }}</p>
      <p class="p-md">{{ text }}</p>
      <div class="options-container mt-16">
        <button
          class="btn btn-sm btn-fluid p-md"
          :class="{ selected: selectionStatuses[index] }"
          v-for="({ text, iconPath, imgPath }, index) in options"
          :key="text"
          @click="select(index)"
        >
        <div class="button-content">
          <img :class="iconPath ? 'icon' : 'image'" :src="iconPath ? getIconSrc(iconPath) : getImgSrc(imgPath)" :alt="text" />
          <span>{{ text }}</span>
        </div>
        </button>
      </div>
    </div>
    <ContinueButton @click="emitSelection" />
  </div>
</template>

<style scoped>
.component-container {
  gap: 16px;
  text-align: left;
  padding-top: 32px;
}

.icon {
  width: 20px;
  height: 20px;
}

.image {
  margin-left: -4px;
  margin-right: -4px;
  width: 40px;
  height: auto;
}

.options-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.btn-fluid {
  width: unset;
}

.btn {
  border-radius: 128px;
  padding: 0 !important;
}

.button-content {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
}

@media screen and (max-width: 600px) {
  .button-content {
    padding: 12px;
  }
  .image {
    width: 30px;
  }
  .options-container {
    gap: 6px
  }

}

</style>
