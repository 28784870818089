<script setup>
import { ref, computed } from 'vue'
import ButtonWithCheck from '../atoms/ButtonWithCheck.vue'
import AsyncComponent from '@/components/atoms/AsyncComponent.vue'
import ContinueButton from '../molecules/ContinueButton.vue'

const { title, subtitle, options, selectedIndexProp, maxSelections } = defineProps([
  'title',
  'subtitle',
  'options',
  'selectedIndex',
  'maxSelections'
])

const maxLimitReached = ref(false)

const selectedIndexes = ref(
  selectedIndexProp
    ? selectedIndexProp.reduce((acc, curr) => {
        acc[curr] = true
        return acc
      }, {})
    : {}
)

const isAnySelected = computed(() => {
  return Object.values(selectedIndexes.value).some((value) => value)
})

const selectIndex = (index) => {
  if (maxLimitReached.value && !selectedIndexes.value[index]) return
  selectedIndexes.value[index] = !selectedIndexes.value[index]
  if (
    maxSelections &&
    Object.values(selectedIndexes.value).filter((item) => item).length == maxSelections
  ) {
    maxLimitReached.value = true
  } else {
    maxLimitReached.value = false
  }
}
</script>

<template>
  <div class="container">
    <div class="component-container component-card">
      <p class="heading-5">{{ title }}</p>
      <p class="p-md">{{ subtitle }}</p>
    </div>
    <div class="options mt-24">
      <button
        :class="`btn btn-sm btn-fluid ${selectedIndexes[index] ? 'selected' : ''}`"
        :disabled="maxLimitReached && !selectedIndexes[index]"
        v-for="(option, index) in options"
        :key="(option.name || option) + (maxLimitReached && !selectedIndexes[index])"
        @click="
          () => {
            selectIndex(index)
          }
        "
      >
        <div class="row">
          <AsyncComponent :name="option.iconComponentName"></AsyncComponent>
          {{ typeof option === 'string' ? option : option.name }}
        </div>
      </button>
    </div>
    <ContinueButton
      :disabled="isAnySelected == false"
      @click="$emit('selection', Object.keys(selectedIndexes))"
    />
  </div>
</template>

<style scoped>

.component-card {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
}

.container {
  width: 100%;
  max-width: 428px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.options {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}


.p-md {
  color: var(--neutrals-500);
  margin-top: 8px;
}

.btn-fluid {
  width: unset;
}

.btn {
  padding: 0px;
  border-radius: 128px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 20px;
}


.button-content {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
}

@media screen and (max-width: 600px) {
  .row {
    padding: 12px;
  }
  .image {
    width: 30px;
  }
  .options-container {
    gap: 6px
  }

}

</style>
