<script setup>
import { computed, ref } from 'vue'
import { sendGTagEvent } from '@/helpers/gtag'
import ChevronRight from '@/assets/icons/chevron-right2.svg'

const { title, options, propSelectedIndex } = defineProps([
  'title',
  'text',
  'options',
  'selectedIndex',
])

const selectedIndex = ref(propSelectedIndex)
const isSelected = computed(() => selectedIndex.value !== undefined)

const emit = defineEmits(['selection'])

// TODO: update send only in gender selection, check text logic
function sendSelectionEventInCase(index) {
  if (options[index]?.sendGTag)
    sendGTagEvent({ event: 'gender_collected', gender: options[index].text })
  emit('selection', index)
}
</script>

<template>
  <div class="component-container component-card">
    <div class="title">
      <p class="heading-5">{{ title }}</p>
      <p class="p-md" v-if="text">{{ text }}</p>
    </div>
    <div class="select-container">
      <button
        :class="`component-card btn p-md mt-8 row ${selectedIndex === index ? 'selected' : ''} ${option.subtext ? 'with-subtext' : ''}`"
        v-for="(option, index) in options"
        :key="option"
        @click="
          () => {
            selectedIndex = index
            sendSelectionEventInCase(index)
          }
        "
      >
        <div class="texts" v-if="option.icon">
          <span class="icon">{{ option.icon }}</span>
          <span>{{ option.text || option }}</span>
        </div>
        <ChevronRight style="color: #0A0D14;" />
        <span class="p-md subtext" v-if="option.subtext">{{ option.subtext }}</span>
      </button>
    </div>
  </div>
</template>

<style scoped>

.component-container {
  display: flex;
  flex-direction: column;
}

.icon {
  font-size: 32px;
}

.texts {
  display: flex;
  align-items: center;
  gap: 12px;
}

.select-container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.btn {
  align-items: center;
  padding: 45px 16px;
}

.title {
  text-align: left;
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subtext {
  margin-left: auto;
  color: var(--neutrals-400);
}

.row {
  display: flex;
  flex-direction: row;
}


@media screen and (max-width: 850px) {
  .select-container {
    padding-top: 0;
    margin-top: 0;
  }

}
</style>
